import axios from 'axios'

export default {
    namespaced: true,

    state: {
        menuOptions: {},        
        modal: false,
        modalIcon: '',
        modalTitle: '',
        modalMessage: '',
        progressOverlay: false,
        editedLesson: {},
        academicTerm: null,
        academicYearId: null,
        lessonOverlay: true,
        postLesson: null,
        deleteLesson: null,
        teacherLessons: [],
        selectedEmployeeId: null,
        formTeacherAssignment: {},
        subjectSelected: null,
        studentId: null,
        employeeId: null,
        employeeSelected: {},
        students: null,
        editedTerm: {},
        editedStudent: {},
        changeClassRecord: {},
        snackbar: {},
        possibleAttendance: {},
        newTermBeginning: {},
        subjects: [],
        selectedTable2Record: {},
        selectedTable1Record: {},
        dialogDelete: {},
        newStudents: [],
        selectedFormClass: null,
        termRegistrationProgress: 0,
        selectedSubject: {},
        selectedStudents: [],
        subjectStudentsAssignment: {},
        studentRegistrationAccess: {},    
        attendanceSummaryDate: null, 
        attendanceSelectedStudent: null,  
        attendanceRecordsSelected: [], 
        selectedTerm: null, 
        studentSelected: null,                     
    },

    getters: {
        getMenuOptions (state) {
            return state.menuOptions
        },
           
        getModal (state) {
            return state.modal
        },

        getModalIcon (state) {
            return state.modalIcon
        },

        getModalTitle (state) {
            return state.modalTitle
        },

        getModalMessage (state) {
            return state.modalMessage
        },

        getProgressOverlay (state) {
            return state.progressOverlay
        },
        
        getEditedLesson (state) {
            return state.editedLesson
        },

        getAcademicTerm (state) {
            return state.academicTerm
        },

        getLessonOverlay (state) {
            return state.lessonOverlay
        },

        getPostLesson (state) {
            return state.postLesson
        },

        getAcademicYearId (state) {
            return state.academicYearId
        },

        getDeleteLesson (state) {
            return state.deleteLesson
        },

        getTeacherLessons (state) {
            return state.teacherLessons
        },

        getSelectedEmployeeId (state) {
            return state.selectedEmployeeId
        },

        getFormTeacherAssignment (state) {
            return state.formTeacherAssignment
        },
       
        getSubjectSelected (state) {
            return state.subjectSelected
        },

        getStudentId (state) {
            return state.studentId
        },

        getEmployeeId (state) {
            return state.employeeId
        },

        getEmployeeSelected (state) {
            return state.employeeSelected
        },

        getStudents (state) {
            return state.students
        },        

        getEditedTerm (state) {
            return state.editedTerm
        },

        getEditedStudent (state) {
            return state.editedStudent
        },

        getChangeClassRecord (state) {
            return state.changeClassRecord
        },

        getSnackbar (state) {
            return state.snackbar
        },

        getPossibleAttendance (state) {
            return state.possibleAttendance
        },

        getNewTermBeginning (state) {
            return state.newTermBeginning
        },

        getSubjects (state) {
            return state.subjects
        },

        getSelectedTable2Record (state) {
            return state.selectedTable2Record
        },

        getDialogDelete (state) {
            return state.dialogDelete
        },

        getSelectedTable1Record (state) {
            return state.selectedTable1Record
        },

        getNewStudents (state) {
            return state.newStudents
        },

        getSelectedFormClass (state) {
            return state.selectedFormClass
        },

        getTermRegistrationProgress (state) {
            return state.termRegistrationProgress
        }, 

        getSelectedSubject (state) {
            return state.selectedSubject
        },

        getSelectedStudents (state) {
            return state.selectedStudents
        },

        getSubjectStudentsAssignment (state) {
            return state.subjectStudentsAssignment
        },

        getStudentRegistrationAccess (state) {
            return state.studentRegistrationAccess
        },

        getAttendanceSummaryDate (state) {
            return state.attendanceSummaryDate
        },

        getAttendanceSelectedStudent (state) {
            return state.attendanceSelectedStudent
        },

        getAttendanceRecordsSelected (state) {
            return state.attendanceRecordsSelected
        },

        getSelectedTerm (state) {
            return state.selectedTerm
        },

        getStudentSelected (state) {
            return state.studentSelected
        },
        
    },

    mutations: {
        setMenuOptions (state, value) {
            state.menuOptions = value
        },
             
        setModal (state, value) {
            state.modal = value
        },

        setModalTitle (state, value) {
            state.modalTitle = value
        },

        setModalIcon (state, value) {
            state.modalIcon = value
        },

        setModalMessage (state, value) {
            state.modalMessage = value
        },

        setProgressOverlay (state, value) {
            state.progressOverlay = value
        },
       
        setEditedLesson (state, value) {
            state.editedLesson = value
        },

        setAcademicTerm (state, value) {
            state.academicTerm = value
        },

        setLessonOverlay (state, value) {
            state.lessonOverlay = value
        },

        setPostLesson (state, value) {
            state.postLesson = value
        },

        setAcademicYearId (state, value) {
            state.academicYearId = value
        },

        setDeleteLesson (state, value) {
            state.deleteLesson = value
        },

        setTeacherLessons (state, value) {
            state.teacherLessons = value
        },

        setSelectedEmployeeId (state, value) {
            state.selectedEmployeeId = value
        },

        setFormTeacherAssignment (state, value) {
            state.formTeacherAssignment = value
        },

        setStudentSubjects (state, value) {
            state.studentSubjects = value
        },

        setSubjectSelected (state, value) {
            state.subjectSelected = value
        },

        setStudentId (state, value) {
            state.studentId = value
        },

        setEmployeeId (state, value) {
            state.employeeId = value
        },

        setEmployeeSelected (state, value) {
            state.employeeSelected = value
        }, 
        
        setEditedTerm (state, value) {
            state.editedTerm = value
        },

        setEditedStudent (state, value) {
            state.editedStudent = value
        },

        setChangeClassRecord (state, value) {
            state.changeClassRecord = value
        },

        setSnackbar (state, value) {
            state.snackbar = value
        },

        setPossibleAttendance (state, value) {
            state.possibleAttendance = value
        },

        setNewTermBeginning (state, value) {
            state.newTermBeginning = value
        },

        setSubjects (state, value) {
            state.subjects = value
        },

        setSelectedTable2Record (state, value) {
            state.selectedTable2Record = value
        }, 

        setDialogDelete (state, value) {
            state.dialogDelete = value
        },

        setSelectedTable1Record (state, value) {
            state.selectedTable1Record = value
        },

        setNewStudents (state, value) {
            state.newStudents = value
        },

        setSelectedFormClass (state, value) {
            state.selectedFormClass = value
        },

        setTermRegistrationProgress (state, value) {
            state.termRegistrationProgress = value
        },

        setSelectedSubject (state, value) {
            state.selectedSubject = value
        },

        setSelectedStudents (state, value) {
            state.selectedStudents = value
        },

        setSubjectStudentsAssignment (state, value) {
            state.subjectStudentsAssignment = value
        },

        setStudentRegistrationAccess (state, value) {
            state.studentRegistrationAccess = value
        },

        setAttendanceSummaryDate (state, value) {
            state.attendanceSummaryDate = value
        },

        setAttendanceSelectedStudent (state, value) {
            state.attendanceSelectedStudent = value
        },

        setAttendanceRecordsSelected (state, value) {
            state.attendanceRecordsSelected = value
        },

        setSelectedTerm (state, value) {
            state.selectedTerm = value
        },

        setStudentSelected (state, value) {
            state.studentSelected = value
        }
        
    },

    actions: {
        async downloadRegistrationData(){
            return axios({
                method: 'get',
                url: '/api/registration-data-spreadsheet',
                responseType: 'arraybuffer',
            });
        },

        async resetPassword(state, id){
            let url = '/api/reset-password-student'
            return axios.post(url, {
                "student_id" : id
            });            
        },

        resetPasswordEmployee ({ getters }) {
            let url = '/api/reset-employee-password';
            return axios.post(url, getters.getEmployeeSelected);
        },

        registerStudents({ getters }){
            let url = '/api/term-reports-register'            
            return axios.post(url, {class_id: getters.getSelectedFormClass});
        },

        getEmployees(){
            let url = '/api/employees';
            return axios.get(url);
        },

        updateEmployee ({ getters }) {
            let url = '/api/update-employee';
            return axios.post(url, getters.getEmployeeSelected);
        },

        addEmployee ({ getters }){
            let url = '/api/add-employee';
            // console.log(getters.getEmployeeSelected);
            return axios.post(url, getters.getEmployeeSelected);
        },

        getFormClasses(){
            let url = 'api/form-classes-list';
            return axios.get(url);
        },

        getTeacherLessons({ getters }, id){
            let url = 'api/teacher-lessons/' + id + '/' + getters.getAcademicYearId;
            // console.log(url);
            return axios.get(url);
        },

        postTeacherLesson({ getters }){
            let url = 'api/teacher-lesson';
            return axios.post(url, getters.getEditedLesson);
        },

        getSubjects(){
            let url = 'api/subjects';
            return axios.get(url);
        },

        setCurrentAcademicTerm({ getters }){
            let url = 'api/current-period';
            console.log(getters.getAcademicTerm);
            return axios.post(url, getters.getAcademicTerm)
        },

        getCurrentAcademicTerm(){
            let url = 'api/current-period';
            return axios.get(url);
        },

        postLesson({ getters }){
            let url = 'api/teacher-lesson';
            console.log(getters.getPostLesson);
            return axios.post(url, getters.getPostLesson);
        },

        deleteLesson({ getters }){
            let url = 'api/delete-teacher-lesson';
            console.log(getters.getDeleteLesson);            
            return axios.post(url, getters.getDeleteLesson);
        },

        getFormTeacherClass({ getters }){
            let url = 'api/form-teacher-class/'
            + getters.getSelectedEmployeeId + '/'
            + getters.getAcademicYearId;
            //console.log(getters.getSelectedEmployeeId);
            return axios.get(url);
        },

        postFormTeacherAssignment({ getters }){
            let url = 'api/form-teacher-class';
            //console.log(getters.getFormTeacherAssignment);
            return axios.post(url, getters.getFormTeacherAssignment);
        },

        getStudents({ rootGetters }){
            let url = 'api/students';
            if(rootGetters['auth/getClassId']){
                url = 'api/students/' + rootGetters['auth/getClassId']
            }
            
            return axios.get(url);
        },

        getSubjectStudents({ getters, rootGetters }){
            let url = 'api/subject-students/' + 
            getters.getSubjectSelected
            
            if(rootGetters['auth/getClassId']){
                url = 'api/subject-students/' + 
                getters.getSubjectSelected + '/' +
                rootGetters['auth/getClassId']
            }   
            return axios.get(url);
        },

        postSubjectStudents({ getters }){
            let url = 'api/subject-students';
            return axios.post(url, {
                employee_id: getters.getEmployeeId,
                subject_id: getters.getSubjectSelected,
                student_id: getters.getStudentId
            })
        },

        deleteSubjectStudent({ getters }){
            let url = 'api/subject-students';            
            return axios.delete(url, {
                data: {
                    students: getters.getSelectedStudents,
                    subject_id: getters.getSubjectSelected
                }           
            })
        },

        getReportsPosted(){
            let url = '/api/term-reports-posted';
            return axios.get(url);
        },

        postTermReports({ getters }){
            let url = 'api/post-term-reports';
            return axios.post(url, getters.getEditedTerm);
        },

        postStudent({ getters}){
            let url = 'api/student';
            return axios.post(url, getters.getEditedStudent);
        },

        changeClass({ getters }){
            let url = 'api/change-class';
            return axios.post(url, getters.getChangeClassRecord);
        },

        postPossibleAttendance ({ getters }) {
            let url = 'api/possible-attendance';
            return axios.post(url, getters.getPossibleAttendance);
        },

        postNewTermBeginning ({ getters }) {
            const url = 'api/new-term-beginning';
            return axios.post(url, getters.getNewTermBeginning);
        },

        postUpdateTable2 ({ getters }) {
            const url = 'api/update-table2';
            return axios.post(url, getters.getSelectedTable2Record);
        },

        deleteTable2Record ({ getters }) {
            const url = 'api/table2-record-delete';
            return axios.post(url, getters.getSelectedTable2Record);
        },

        deleteTable1Record ({ getters }) {
            const url = 'api/table1';
            // console.log(getters.getSelectedTable1Record);
            return axios.delete(url, {data: getters.getSelectedTable1Record});
        },

        dowloadRegistrationTemplate () {
            return axios({
                method: 'get',
                url: '/api/student-upload-template',
                responseType: 'arraybuffer',
            });
        },

        uploadStudents ({ getters }) {
            const url = 'api/upload-new-students';            
            return axios.post(url, {data: JSON.stringify(getters.getNewStudents)}, 
           ); 
        },

        getFormLevels () {
            const url = 'api/form-levels';
            return axios.get(url);
        },

        postSubject ({ getters }) {
            const url = 'api/subject';
            return axios.post(url, getters.getSelectedSubject);
        },        
        
        deleteSubject ({ getters }) {
            const url = 'api/subject';
            return axios.delete(url, {data: getters.getSelectedSubject});
        },

        postSubjectStudentsAssignmentBatch ({ getters }) {
            const url = 'api/subject-students-batch';            
            return axios.post(url, getters.getSubjectStudentsAssignment);
        },

        getEmployeePosts () {
            const url = 'api/employee-posts';
            return axios.get(url);
        },

        getAcademicYears () {
            const url = 'api/academic-years';
            return axios.get(url);
        },

        getStudentRegistration () {
            const url = 'api/student-registration';
            return axios.get(url);
        },

        postStudentRegistrationStatus ({ getters }) {
            const url = 'api/student-registration';
            return axios.post(url, getters.getStudentRegistrationAccess);
        },

        getFormClass ({ rootGetters }) {
            const url = 'api/form-class/' + rootGetters['auth/getClassId'];
            // console.log(url)
            return axios.get(url);
        },

        getAttendanceSummaryData ({ getters }) {
            const url = 'api/attendance-report-summary-data'
            return axios.get(url, {
                params: {
                    date: getters.getAttendanceSummaryDate
                }
            })
        },

        getAttendanceRecords({ getters }){
            const url = '/api/student-attendance/' + getters.getAttendanceSelectedStudent.id;
            console.log(url);
            return axios.get(url);
        },

        deleteAttendanceStudent({ getters }){
            let url = 'api/student-attendance';
            return axios.delete(url, {
                data: {
                    records: getters.getAttendanceRecordsSelected,
                }           
            })
        },

        getTermDetails () {
            const url = 'api/academic-term-details';
            return axios.get(url);
        },

        postTermDetails ({ getters }) {
            const url = 'api/academic-term-details';
            return axios.post(url, getters.getSelectedTerm);
        },

        archiveStudentAttendance () {
            const url = 'api/student-attendance-archive';
            return axios.post(url);
        },

        getStudentsRegistered () {
            const url = 'api/term-reports-students-registered';
            return axios.get(url);
        },

        postStudentReportAttendance ({ getters }) {
            const url = 'api/report-attendance'
            return axios.post(url, { student_id : getters.getStudentSelected})
        }

    }
}    